table.dealers-import-table {
    border-collapse: collapse;
    border: 1px solid darkgrey;
}

table.dealers-import-table thead {
    background-color: grey;
    color: #fff;
}

table.dealers-import-table tbody {
    background-color: white;
}

table.dealers-import-table td,
table.dealers-import-table th {
    padding: 5px 10px;
    border: 1px solid lightgrey;
}

table.dealers-import-table th:first-child {
    text-align: right;
}

table.dealers-import-table.selection-data tr>* {
    user-select: auto;
}
table.dealers-import-table.selection-data tr>*:first-child {
    user-select: none;
    background-color: grey;
}

table.dealers-import-table.selection-meta tr>* {
    user-select: none;
    background-color: grey;
}
table.dealers-import-table.selection-meta tr>*:first-child {
    user-select: auto;
}
table.dealers-import-table.selection-meta tbody>tr>*:first-child {
    background-color: white;
}